const routesName = {
  login: "login",
  orders: "orders",
  ordersNewGames: "ordersNewGames",
  ordersNewGameEdit: "ordersNewGameEdit",
  ordersActiveGames: "ordersActiveGames",
  ordersActiveGameEdit: "ordersActiveGameEdit",
  ordersChangedGames: "ordersChangedGames",
  ordersChangedGameEdit: "ordersChangedGameEdit",
  ordersOnHoldGames: "ordersOnHoldGames",
  ordersOnHoldGameEdit: "ordersOnHoldGameEdit",
  ordersCompletedGames: "ordersCompletedGames",
  ordersCompletedGameEdit: "ordersCompletedGameEdit",
  ordersCanceledGames: "ordersCanceledGames",
  ordersNewGameDetails: "ordersNewGameDetails",
  ordersActiveGameDetails: "ordersActiveGameDetails",
  ordersChangedGameDetails: "ordersChangedGameDetails",
  ordersOnHoldGameDetails: "ordersOnHoldGameDetails",
  ordersCompletedGameDetails: "ordersCompletedGameDetails",
  ordersCanceledGameDetails: "ordersCanceledGameDetails",
  ordersCanceledGameEdit: "ordersCanceledGameEdit",
  ordersDepositForSeason: "ordersDepositForSeason",
  ordersDepositDetails: "ordersDepositDetails",
  ordersDepositEdit: "ordersDepositEdit",
  ordersLiveStreaming: "ordersLiveStreaming",
  ordersLiveStreamingDetails: "ordersLiveStreamingDetails",
  ordersLiveStreamingEdit: "ordersLiveStreamingEdit",
  ordersAdditionalWindows: "ordersAdditionalWindows",
  ordersAdditionalWindowDetails: "ordersAdditionalWindowDetails",
  ordersAdditionalWindowEdit: "ordersAdditionalWindowEdit",
  ordersScoutGames: "ordersScoutGames",
  ordersScoutGamesDetails: "ordersScoutGamesDetails",
  ordersScoutGamesEdit: "ordersScoutGamesEdit",
  ordersVideographer: "ordersVideographer",
  videographers: "videographers",
  videographerExperience: "videographerExperience",
  videographerCreate: "videographerCreate",
  videographerEdit: "videographerEdit",
  videographerDetails: "videographerDetails",
  videographerAssignedGames: "videographerAssignedGames",
  corporateClients: "corporateClients",
  corporateCreate: "corporateCreate",
  corporateEdit: "corporateEdit",
  corporateInfo: "corporateInfo",
  usersIndex: "usersIndex",
  userCreate: "userCreate",
  userEdit: "userEdit",
  coupons: "coupons",
  couponCreate: "couponCreate",
  couponEdit: "couponEdit",
  couponDetails: "couponDetails",
  resetPassword: "resetPassword",
  statistics: "statistics",
  distributions: "distributions",
  notFound: "notFound",
};

export default routesName;
