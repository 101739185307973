import { RouteRecordRaw } from "vue-router";
import routesMetaTypes from "@/utils/constants/routesMetaTypes";
import routesName from "@/utils/constants/routesName";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: { name: routesName.ordersNewGames },
  },
  {
    path: "/orders",
    name: routesName.orders,
    redirect: { name: routesName.ordersNewGames },
    component: () => import("@/views/OrdersView.vue"),
    meta: { type: routesMetaTypes.orders, protected: true },
    children: [
      {
        path: "new",
        name: routesName.ordersNewGames,
        component: () => import("@/views/OrdersNewGames.vue"),
        meta: {
          type: routesMetaTypes.newGames,
          protected: true,
          viewTypeToggle: true,
        },
      },
      {
        path: "new/game/:id",
        name: routesName.ordersNewGameDetails,
        component: () => import("@/views/OrdersGameDetails.vue"),
        meta: { type: routesMetaTypes.newGames, protected: true },
      },
      {
        path: "new/game/:id/edit",
        name: routesName.ordersNewGameEdit,
        component: () => import("@/views/OrdersGameEdit.vue"),
        meta: { type: routesMetaTypes.newGames, protected: true },
      },
      {
        path: "active",
        name: routesName.ordersActiveGames,
        component: () => import("@/views/OrdersActiveGames.vue"),
        meta: {
          type: routesMetaTypes.activeGames,
          protected: true,
          viewTypeToggle: true,
        },
      },
      {
        path: "active/game/:id",
        name: routesName.ordersActiveGameDetails,
        component: () => import("@/views/OrdersGameDetails.vue"),
        meta: { type: routesMetaTypes.activeGames, protected: true },
      },
      {
        path: "active/game/:id/edit",
        name: routesName.ordersActiveGameEdit,
        component: () => import("@/views/OrdersGameEdit.vue"),
        meta: { type: routesMetaTypes.activeGames, protected: true },
      },
      {
        path: "changed",
        name: routesName.ordersChangedGames,
        component: () => import("@/views/OrdersChangedGames.vue"),
        meta: {
          type: routesMetaTypes.changedGames,
          protected: true,
          viewTypeToggle: true,
        },
      },
      {
        path: "changed/game/:id",
        name: routesName.ordersChangedGameDetails,
        component: () => import("@/views/OrdersGameDetails.vue"),
        meta: { type: routesMetaTypes.changedGames, protected: true },
      },
      {
        path: "changed/game/:id/edit",
        name: routesName.ordersChangedGameEdit,
        component: () => import("@/views/OrdersGameEdit.vue"),
        meta: { type: routesMetaTypes.changedGames, protected: true },
      },
      {
        path: "on-hold",
        name: routesName.ordersOnHoldGames,
        component: () => import("@/views/OrdersOnHoldGames.vue"),
        meta: {
          type: routesMetaTypes.onHoldGames,
          protected: true,
          viewTypeToggle: true,
        },
      },
      {
        path: "on-hold/game/:id",
        name: routesName.ordersOnHoldGameDetails,
        component: () => import("@/views/OrdersGameDetails.vue"),
        meta: { type: routesMetaTypes.onHoldGames, protected: true },
      },
      {
        path: "on-hold/game/:id/edit",
        name: routesName.ordersOnHoldGameEdit,
        component: () => import("@/views/OrdersGameEdit.vue"),
        meta: { type: routesMetaTypes.onHoldGames, protected: true },
      },
      {
        path: "completed",
        name: routesName.ordersCompletedGames,
        component: () => import("@/views/OrdersCompletedGames.vue"),
        meta: {
          type: routesMetaTypes.completedGames,
          protected: true,
          viewTypeToggle: true,
        },
      },
      {
        path: "completed/game/:id",
        name: routesName.ordersCompletedGameDetails,
        component: () => import("@/views/OrdersGameDetails.vue"),
        meta: { type: routesMetaTypes.completedGames, protected: true },
      },
      {
        path: "completed/game/:id/edit",
        name: routesName.ordersCompletedGameEdit,
        component: () => import("@/views/OrdersGameEdit.vue"),
        meta: { type: routesMetaTypes.completedGames, protected: true },
      },
      {
        path: "canceled",
        name: routesName.ordersCanceledGames,
        component: () => import("@/views/OrdersCanceledGames.vue"),
        meta: {
          type: routesMetaTypes.canceledGames,
          protected: true,
          viewTypeToggle: true,
        },
      },
      {
        path: "canceled/game/:id",
        name: routesName.ordersCanceledGameDetails,
        component: () => import("@/views/OrdersGameDetails.vue"),
        meta: { type: routesMetaTypes.canceledGames, protected: true },
      },
      {
        path: "canceled/game/:id/edit",
        name: routesName.ordersCanceledGameEdit,
        component: () => import("@/views/OrdersGameEdit.vue"),
        meta: { type: routesMetaTypes.canceledGames, protected: true },
      },
      {
        path: "deposit-for-season",
        name: routesName.ordersDepositForSeason,
        component: () => import("@/views/OrdersDepositForSeason.vue"),
        meta: { type: routesMetaTypes.depositForSeason, protected: true },
      },
      {
        path: "deposit-for-season/:id",
        name: routesName.ordersDepositDetails,
        component: () => import("@/views/OrdersDepositDetails.vue"),
        meta: { type: routesMetaTypes.depositForSeason, protected: true },
      },
      {
        path: "deposit-for-season/:id/edit",
        name: routesName.ordersDepositEdit,
        component: () => import("@/views/OrdersDepositEdit.vue"),
        meta: { type: routesMetaTypes.depositForSeason, protected: true },
      },
      {
        path: "live-streaming",
        name: routesName.ordersLiveStreaming,
        component: () => import("@/views/OrdersLiveStreaming.vue"),
        meta: { type: routesMetaTypes.liveStreaming, protected: true },
      },
      {
        path: "live-streaming/:id",
        name: routesName.ordersLiveStreamingDetails,
        component: () => import("@/views/OrdersLiveStreamingDetails.vue"),
        meta: { type: routesMetaTypes.liveStreaming, protected: true },
      },
      {
        path: "live-streaming/:id/edit",
        name: routesName.ordersLiveStreamingEdit,
        component: () => import("@/views/OrdersLiveStreamingEdit.vue"),
        meta: { type: routesMetaTypes.liveStreaming, protected: true },
      },
      {
        path: "additional",
        name: routesName.ordersAdditionalWindows,
        component: () => import("@/views/OrdersAdditionalWindows.vue"),
        meta: { type: routesMetaTypes.additionalWindows, protected: true },
      },
      {
        path: "additional/:id",
        name: routesName.ordersAdditionalWindowDetails,
        component: () => import("@/views/OrdersAdditionalWindowDetails.vue"),
        meta: { type: routesMetaTypes.additionalWindows, protected: true },
      },
      {
        path: "additional/:id/edit",
        name: routesName.ordersAdditionalWindowEdit,
        component: () => import("@/views/OrdersAdditionalWindowEdit.vue"),
        meta: { type: routesMetaTypes.additionalWindows, protected: true },
      },
      {
        path: "scout-games",
        name: routesName.ordersScoutGames,
        component: () => import("@/views/OrdersScoutGames.vue"),
        meta: { type: routesMetaTypes.scoutGames, protected: true },
      },
      {
        path: "scout-games/:id",
        name: routesName.ordersScoutGamesDetails,
        component: () => import("@/views/OrdersScoutGamesDetails.vue"),
        meta: { type: routesMetaTypes.scoutGames, protected: true },
      },
      {
        path: "scout-games/:id/edit",
        name: routesName.ordersScoutGamesEdit,
        component: () => import("@/views/OrdersScoutGamesEdit.vue"),
        meta: { type: routesMetaTypes.scoutGames, protected: true },
      },
      {
        path: "videographer/:id",
        name: routesName.ordersVideographer,
        component: () => import("@/views/OrdersVideographer.vue"),
        meta: { protected: true, viewTypeToggle: true },
      },
    ],
  },
  {
    path: "/distributions",
    name: routesName.distributions,
    component: () => import("@/views/DistributionsView.vue"),
    meta: {
      protected: true,
      type: routesMetaTypes.distributions,
    },
  },
  {
    path: "/login",
    name: routesName.login,
    component: () => import("@/views/LoginView.vue"),
    meta: {
      notAuthorized: true,
    },
  },
  {
    path: "/reset-password",
    name: routesName.resetPassword,
    component: () => import("@/views/ResetPasswordView.vue"),
    meta: {
      notAuthorized: true,
    },
  },
  {
    path: "/videographers",
    name: routesName.videographers,
    component: () => import("@/views/VideographersView.vue"),
    meta: { type: routesMetaTypes.videographers, protected: true },
  },
  {
    path: "/videographers/:id/experience",
    name: routesName.videographerExperience,
    component: () => import("@/views/VideographerExperienceView.vue"),
    meta: { type: routesMetaTypes.videographers, protected: true },
  },
  {
    path: "/videographers/create",
    name: routesName.videographerCreate,
    component: () => import("@/views/VideographerCreateView.vue"),
    meta: { type: routesMetaTypes.videographers, protected: true },
  },
  {
    path: "/videographers/:id/edit",
    name: routesName.videographerEdit,
    component: () => import("@/views/VideographerEditView.vue"),
    meta: { type: routesMetaTypes.videographers, protected: true },
  },
  {
    path: "/videographers/:id/details",
    name: routesName.videographerDetails,
    component: () => import("@/views/VideographerDetailsView.vue"),
    meta: { type: routesMetaTypes.videographers, protected: true },
  },
  {
    path: "/videographers/:id/assigned-games",
    name: routesName.videographerAssignedGames,
    component: () => import("@/views/VideographerAssignedGames.vue"),
    meta: {
      type: routesMetaTypes.videographers,
      protected: true,
      viewTypeToggle: true,
    },
  },
  {
    path: "/corporate-clients",
    name: routesName.corporateClients,
    component: () => import("@/views/CorporateClientsView.vue"),
    meta: { type: routesMetaTypes.corporateClients, protected: true },
  },
  {
    path: "/corporate-clients/create",
    name: routesName.corporateCreate,
    component: () => import("@/views/CorporateCreateView.vue"),
    meta: { type: routesMetaTypes.corporateClients },
  },
  {
    path: "/corporate-clients/:id/edit",
    name: routesName.corporateEdit,
    component: () => import("@/views/CorporateEditView.vue"),
    meta: { type: routesMetaTypes.corporateClients },
  },
  {
    path: "/corporate-clients/:id/info",
    name: routesName.corporateInfo,
    component: () => import("@/views/CorporateInfoView.vue"),
    meta: { type: routesMetaTypes.corporateClients },
  },
  {
    path: "/users",
    name: routesName.usersIndex,
    component: () => import("@/views/UsersView.vue"),
    meta: { type: routesMetaTypes.users, protected: true, admin: true },
  },
  {
    path: "/user/create",
    name: routesName.userCreate,
    component: () => import("@/views/UserCreateView.vue"),
    meta: { type: routesMetaTypes.users, protected: true, admin: true },
  },
  {
    path: "/user/:id/edit",
    name: routesName.userEdit,
    component: () => import("@/views/UserEditView.vue"),
    meta: { type: routesMetaTypes.users, protected: true, admin: true },
  },
  {
    path: "/coupons",
    name: routesName.coupons,
    component: () => import("@/views/CouponsView.vue"),
    meta: { type: routesMetaTypes.coupons, protected: true },
  },
  {
    path: "/coupon/create",
    name: routesName.couponCreate,
    component: () => import("@/views/CouponCreateView.vue"),
    meta: { type: routesMetaTypes.coupons, protected: true },
  },
  {
    path: "/coupon/:id/edit",
    name: routesName.couponEdit,
    component: () => import("@/views/CouponEditView.vue"),
    meta: { type: routesMetaTypes.coupons, protected: true },
  },
  {
    path: "/statistics",
    name: routesName.statistics,
    component: () => import("@/views/StatisticsView.vue"),
    meta: { type: routesMetaTypes.statistics, protected: true },
  },
  {
    path: "/:pathMatch(.*)*",
    name: routesName.notFound,
    meta: {
      protected: true,
      fullPage: true,
    },
    component: () => import("@/views/NotFoundView.vue"),
  },
];

export default routes;
